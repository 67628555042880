<template>
  <BaseCard
   :footer-visible="false"
   :caption-visible="false"
 >
   <template #title>
     <span class="h3 font-weight-bold mb-0 text-uppercase">
      Pedidos categorías
     </span>
   </template>
   <OrdersProductsCategoriesTable />
 </BaseCard>
</template>


<script>
import OrdersProductsCategoriesTable from '@/components/orders-products-categories/table/OrdersProductsCategoriesTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
 components: { 
  BaseCard, 
  OrdersProductsCategoriesTable
},
}
</script>
