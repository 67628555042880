<template>
  <div>
    <BaseTable
      ref="orders-products"
      :columns="columns"
      :totals-configuration="totalsConfiguration"
      :resource="$data.$constants.RESOURCES.RESOURCE_ORDERS_PRODUCTS"
      :extraResourceList="'list-product-categories'"
      :resourceStore="'orderProducts'"
      :extraResourceExport="'export-product-categories'"
      :loading="loading"
      :filters="filters"
      :disablePagination="true"
      :hidePrinterButton="true"
      :hideImportButton="true"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
    >
      <template #cell(total_units)="data">
        {{ data.value | numberToLocalString }}
      </template>
      <template #cell(total_amount)="data">
        {{ data.value | numberToLocalString }} €
      </template>
    </BaseTable>
    <OrdersProductsCategoriesListFilters
      ref="order-products-categories-list-filters"
      v-model="filtersVisible"
      @filters-submit="
        $store.commit('ordersProductsCategories/setFilters', $event)
      "
    />
  </div>
</template>

<script>
import OrdersProductsCategoriesListFilters from "@/components/orders-products-categories/filters/OrdersProductsCategoriesListFilters.vue";
import BaseTable from "@/components/ui/table/BaseTable.vue";
import { mapGetters } from "vuex";

export default {
  name: "OrdersProductsCategoriesTable",
  components: {
    BaseTable,
    OrdersProductsCategoriesListFilters,
  },
  data() {
    return {
      filtersVisible: false,
      loading: false,
      totalsConfiguration: [
        { colspan: 1 },
        { label: "Unidades", key: "units", unit: "" },
        { label: "Total", key: "total", unit: "€" },
      ],
      columns: [
        {
          label: "Categoría",
          key: "category_name",
          sortable: false,
        },
        {
          label: "Unidades",
          key: "total_units",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: "TOTAL",
          key: "total_amount",
          sortable: false,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filters: "ordersProductsCategories/getFilters",
    }),
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit("ordersProductsCategories/setFilters", {});
      this.$refs["order-products-categories-list-filters"].clearFilters();
    },
  },
};
</script>
