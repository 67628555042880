<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label> Fecha entre </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_min"
                class="margin-right-datepicker"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.date_max"
                @input="update('date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_max"
                class="margin-left-datepicker"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.date_min"
                @input="update('date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Grupo de categorías" label-for="categoríes">
          <BaseSelect
            id="categoríes"
            :value="model.category_group"
            :resource="$data.$constants.RESOURCES.RESOURCE_CATEGORY_GROUPS"
            @input="update('category_group', $event)"
          />
            <!-- :httpMethod="'get'" -->
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Categoría" label-for="product-category">
            <CategorySelect
                :value="model.product_category"
                  @input="update('product_category', $event)"    
              />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Proveedores" label-for="providers">
          <BaseSelect
            id="providers"
            :value="model.provider"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
            @input="update('provider', $event)"
            />
            <!-- :httpMethod="'get'" -->
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from "@/mixins/formMixin";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import CategorySelect from "@/components/category/select/CategorySelect.vue";

export default {
  name: "OrderProductsCategoriesFiltersForm",
  components: {
    BaseSelect,
    CategorySelect,
  },
  mixins: [FormMixin],
};
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
</style>
